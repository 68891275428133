import { STORAGE_BASE_URL } from "@/utils/constants";
import * as dayjs from "dayjs";

export function scrollToTop(window) {
  window.scrollTo(0,0);
}

// TODO: fix: remove this function and manage them backend side
export function convertTownToTownship(name) {
  const parseSpace = name.split(' ');
  const parseCote = parseSpace[parseSpace.length-1].split("'");
  const fileName = parseCote[parseCote.length-1];
  return fileName === 'District' ? 'Plateau' : fileName;
}

export function truncateString(str, num) {
  if (!str || str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

export function arraySlice(arrayToSlice, chunkSize) {
  if(!arrayToSlice || !chunkSize) return []
  const chunkedCollection = []
  for (let i = 0; i < arrayToSlice.length; i += chunkSize) {
    const chunkedArray = arrayToSlice.slice(i, i + chunkSize);
    chunkedCollection.push(chunkedArray)
  }
  return chunkedCollection
}

const token = localStorage.getItem('token')

export const requestHeaders = (token, additionalHeader) => {
  let headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
  if(additionalHeader) {
    headers = {...headers, ...additionalHeader}
  }

  if(token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  return headers;
}

export const requestConfig = (params, additionalHeader) => ({
  params, mode: 'cors', headers: requestHeaders(token, additionalHeader)
})

export const randomKey = (data) => Math.floor(Math.random() * data.length);

const videoId = (value, urlPreffix = 'https://img.youtube.com/vi/', urlSuffix = '/hqdefault.jpg') => {
  const yo = value.split('watch?v=')
  const yo2 = yo[yo.length - 1].split("&")
  const yo3 = yo2[0]
  return `${urlPreffix + yo3 + urlSuffix}`
}

export const getGoodPath = (media, isPreview = false) => {
  const { type, path } = media;
  if(type === 'youtube_video' && path && isPreview) {
    return videoId(path)
  }
  if(type === 'youtube_video' && path && !isPreview) {
    return videoId(path, 'https://www.youtube.com/embed/', '')
  }
  if(type === 'photo' && path) { return STORAGE_BASE_URL + path }
  return null
}

export const dateFormatter = (date, format = 'DD MMMM YYYY') => dayjs(date).format(format)


