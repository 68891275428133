<template>
  <div class="w-full">
    <div class="relative flex" v-if="media_previews && media_previews.length === 3">
      <div class="flex flex-1">
        <img class="h-full w-full object-cover" :src="media_previews[0]" />
      </div>
      <div class="flex flex-col flex-1 relative">
        <div class="flex h-[15rem]">
          <img class="h-full w-full object-cover" :src="media_previews[1]" />
        </div>
        <div class="flex h-[15rem]">
          <img class="h-full w-full object-cover" :src="media_previews[2]" />
        </div>
      </div>
      <button class="absolute text-red-800 border-red-800 right-0 bottom-0 border px-3 py-1.5 hover:text-white hover:bg-red-800" v-if="medias && medias.length > 0">
        {{ `Voir ${medias.length} medias` }}
      </button>
    </div>
  </div>
</template>

<script>
import {STORAGE_BASE_URL, getGoodPath} from "@/utils";

const mapMediasToDisplay = (medias) => {
  const mediasToDisplay = medias;
  if(medias.length < 3) {
    const rest = 3 - medias.length;
    for (let i = 1; i <= rest; i++) {
      mediasToDisplay.push(medias[rest - 1]);
    }
  } else if (this.medias.length > 3) {
    for (let i = 1; i <= 3; i++) {
      mediasToDisplay.push(medias[i]);
    }
  }
  return mediasToDisplay;
}

export default {
  name: 'portfolio-preview-component',
  props: ['medias'],
  data: () => ({
    storageBaseUrl: STORAGE_BASE_URL,
    media_previews: [],
    currentMedia: null,
    displayModal: false,
  }),
  watch: {
    medias(next) {
      if (next) {
        this.media_previews = mapMediasToDisplay(this.medias).map(media => getGoodPath(media, true));
      }
    }
  }
}
</script>

<style scoped>

</style>