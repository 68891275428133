<template>
  <div>
    <div class="flex items-center">
      <div v-if="stars_to_display && stars_to_display.length > 0" class="flex">
        <div class="flex" v-if="isShownMode">
          <div v-for="(star, key) in stars_to_display" :key="key">
            <font-awesome-icon icon="fa-star"
                               :class="{
                 'text-gray-200': star.type === starType.EMPTY,
                 'text-yellow-400': star.type === starType.FULL
              }" />
          </div>
        </div>
        <div class="flex" v-else>
          <button v-for="(_, index) in stars_to_display" :key="index + 1" @focus="onFocus(index + 1)" @click="onSelected(index + 1)">
            <font-awesome-icon
                icon="fa-star"
                :class="{'text-gray-200': (index + 1) > currentKey, 'text-yellow-400': (index + 1) <= currentKey}"
            />
          </button>
        </div>
      </div>
      <div v-if="count" class="count-container">
        {{ (count) }}
      </div>
    </div>
  </div>
</template>

<script>
import {COMPONENT_STAR_TYPES} from "@/types";

export default {
  name: 'ReviewStarsComponent',
  props: ['componentId', 'count', 'isShownMode', 'stars'],
  data() {
    return { starType: COMPONENT_STAR_TYPES, currentKey: 0, isReady: false, stars_to_display: [] }
  },
  mounted() {
    if (!this.stars) {
      this.stars_to_display = [{ type: COMPONENT_STAR_TYPES.EMPTY }, { type: COMPONENT_STAR_TYPES.EMPTY }, { type: COMPONENT_STAR_TYPES.EMPTY }]
    } else {
      this.stars_to_display = this.stars
    }
  },
  watch: {
    stars(val) {
      if (!val) {
        this.stars_to_display = [{ type: COMPONENT_STAR_TYPES.EMPTY }, { type: COMPONENT_STAR_TYPES.EMPTY }, { type: COMPONENT_STAR_TYPES.EMPTY }]
      } else {
        this.stars_to_display = val
      }
      return val
    }
  },
  methods: {
    onSelected(key) {
      this.$emit('on-mark-selected', {componentId: this.componentId, mark: key})
    },
    onFocus(key) {
      this.currentKey = key
    }
  }
}
</script>
