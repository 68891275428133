<template>
  <div class="py-2 flex flex-col w-full text-sm px-1">
    <button class="flex justify-between filter-header border-b pb-2 items-center cursor-pointer" @click="toggleDisplay">
      <span class="text-left">Communes</span>&nbsp;
      <font-awesome-icon class="text-gray-600" :icon="isDisplay ? 'fa-angle-up' : 'fa-angle-down'"></font-awesome-icon>
    </button>
    <div class="py-2 flex flex-col" v-show="isDisplay">
      <div class="py-1 hover:text-red-800" v-for="(town, key) in data" :key="key">
        <button
            @click="onTownFilterSelected(town.id)" class="filter-item-action"
            :class="{'text-red-800': currentTownId === town.id}"
        >
          {{ town.name }}&nbsp;&nbsp;
          <font-awesome-icon v-if="currentTownId === town.id" icon="fa-xmark"></font-awesome-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { convertTownToTownship } from "@/utils";

export default {
  name: 'FilterItemsComponent',
  props: {
    data: {
      type: Array,
      required: true
    },
    currentTownId: {
      type: Number,
      default: null
    },
    isOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isDisplay: this.isOpen
    }
  },
  methods: {
    onTownFilterSelected(townId) {
      this.$emit('town-filter-selected', townId);
    },
    convertTownToTownshipWrapper(name) {
      return convertTownToTownship(name);
    },
    toggleDisplay() {
      this.isDisplay = !this.isDisplay
    }
  }
}
</script>

<style>
.filter-header {
  width: 100%;
}
.filter-item {
  padding: 0 0.25rem;
}
.filter-item:hover {
  color: rgb(183 28 28 / 100%);
}
.filter-body {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  align-items: center;
}

.filter-item-action {
  padding: 0;
}

.filter-item-action.active {
  color: rgb(183 28 28 / 100%);
  border: 1px solid rgb(183 28 28 / 100%);
  padding: 0.25rem 1rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
}
</style>
