const baseUrls = {
  DEV: 'http://localhost:8001',
  TEST: 'https://wedding-providers-joursdejoie.paraclet-bk.com'
}
export const BASE_URL = baseUrls.TEST;

const authBaseUrls = {
  DEV: 'http://localhost:8000',
  TEST: 'https://authentication.joursdejoie.paraclet-bk.com',
}

export const AUTH_BASE_URL = authBaseUrls.TEST;

const appBaseUrls = {
  DEV: 'http://localhost:8080',
  TEST: 'https://wedding-providers-joursdejoie-front.paraclet-bk.com',
};

const APP_BASE_URL = appBaseUrls.TEST;

export const TOWN_HALL_BASE_URL = 'https://town-hall.joursdejoie.paraclet-bk.com'

export const STORAGE_BASE_URL = `${BASE_URL}/storage/`;

const reviewMock = (key) => ({
  name: "john doe " + key,
  path: "photos/default.png",
  mark: 5,
  title: "Lorem ipsum dolor sit amet",
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
  created_at: "2021-09-09"
})
export const reviewsMock = [1, 2, 3].map((key) => ({...reviewMock(key)}));

export const QUOTE_REQUEST_TYPE = {
  MESSAGE: 'MESSAGE',
  PHONE: 'PHONE',
  WEBSITE: 'WEBSITE',
};

export const LOGIN_URL = `${AUTH_BASE_URL}/login?app_id=CLIENT_SPACE&redirect=${APP_BASE_URL}/callback`