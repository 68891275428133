<template>
  <div>
    <header>
      <div class="flex flex-col w-full items-center">
        <div class="w-full">
          <div class="flex container mx-auto">
            <nav-list-component current-page="wedding-providers"></nav-list-component>
          </div>
        </div>
        <div class="flex w-full">
          <banner-component
              @clear-filters-on-banner="onClearFilters"
              @town-filter-selected-on-banner="onTownFilterSelected"
              @service-type-filter-selected-on-banner="onServiceTypeFilterSelected"
              :is-display-search-inputs="true"
          ></banner-component>
        </div>
      </div>
    </header>
    <div class="container mx-auto px-4">
      <div class="flex justify-between items-center pt-8 border-b pb-4">
        <div class="flex">
          20861 Prestataires inscrits
        </div>
        <div class="hidden md:flex">
          <pill-component
              icon_color_class="text-red-800" bg_color_class="bg-white border-gray-200 border"
              icon="fa-solid fa-th" :action="() => setIsList(false)" text="Mosaique"
          ></pill-component>&nbsp;
          <pill-component
              icon_color_class="text-red-800" bg_color_class="bg-white border-gray-200 border"
              icon="fa-solid fa-list" :action="() => setIsList(true)" text="Liste"
          ></pill-component>
        </div>
        <div class="flex md:hidden">
          <pill-component
              icon_color_class="text-red-800" bg_color_class="bg-white border-gray-200 border"
              icon="fa-solid fa-th" :action="onMobileFiltersPanelClick" text="Filtres"
          ></pill-component>&nbsp;
        </div>
      </div>

      <div class="flex flex-row w-full">
        <div class="hidden md:flex md:w-1/3 lg:w-1/5">
          <filter-list-component
              :currentServiceTypeId="currentServiceTypeId"
              :currentTownId="currentTownId"
              @search-submit="onSearchSubmit"
              @town-filter-selected-on-list="onTownFilterSelected"
              @service-type-filter-selected-on-list="onServiceTypeFilterSelected"
              @price-filter-selected-on-list="onPriceFilterSelected"
              @review-filter-selected-on-list="onReviewFilterSelected"
          />
        </div>
        <div class="flex flex-col w-full pt-5 h-fit md:2/3 lg:w-4/5 pl-2">
          <div class="flex w-full md:hidden" v-if="isDisplayMobileFilters">
            <filter-list-component
                :isOpen="false"
                :currentServiceTypeId="currentServiceTypeId"
                :currentTownId="currentTownId"
                @search-submit="onSearchSubmit"
                @town-filter-selected-on-list="onTownFilterSelected"
                @service-type-filter-selected-on-list="onServiceTypeFilterSelected"
                @price-filter-selected-on-list="onPriceFilterSelected"
                @review-filter-selected-on-list="onReviewFilterSelected"
            />
          </div>
          <div class="pt-1 pb-2">
            <card-list-component v-if="providers.data && providers.data.length > 0" @on-favorite-toggle="onFavorite" :data="providers.data" :is-list="isList"></card-list-component>
          </div>
          <div class="flex justify-center" v-if="providers.data && providers.data.length === 0">
            Aucun prestataire associé à cette entrée...
          </div>
          <div class="py-2">
            <pagination-component v-if="pagination && providers.data && providers.data.length > 0" :pagination="pagination" @paginate="onChangePage"></pagination-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { PROVIDER_LIST } from "@/mocks/providers";
import { scrollToTop } from '@/utils';
import FilterListComponent from "@/components/filter/filter-list";
import PillComponent from "@/components/pill/pill";

export default {
  name: 'provider-index-new',
  components: {FilterListComponent, PillComponent},
  layout: 'default',
  data() {
    return {
      cards: PROVIDER_LIST,
      isList: false,
      currentPage: 1,
      searchTxt: '',
      isDisplayMobileFilters: false,
      currentTownId: null,
      currentServiceTypeId: null,
      filters: {
        min_price: 0,
        max_price: 0,
        mark: 0,
      },
      favList: [],
    }
  },
  computed: {
    ...mapState('providers', {
      isLoading: 'isLoading',
      providers: 'providers',
    }),
    ...mapState('auth', {
      isAuthLoading: 'isLoading',
      user: 'user',
    }),
    pagination() {
      if(!this.providers) {
        return null;
      }
      return {
        currentPage: this.providers.current_page,
        from: this.providers.from,
        to: this.providers.to,
        total: this.providers.total,
        lastPage: this.providers.last_page,
        perPage: this.providers.per_page,
      }
    },
  },
  async mounted() {
    const filters = {
      page: this.currentPage,
      service_type_id: this.$route.query.service_type_id,
      town_id: this.$route.query.town_id,
    }
    await this.getProviders(filters)
    await this.getTowns()
    await this.getServiceTypes()
    if(this.user && !this.isAuthLoading) {
      await this.getFavorites({customer_id: this.user.id, ids_only: 0})
    }
  },
  methods: {
    ...mapActions({
      getServiceTypes: 'serviceTypes/getServiceTypes',
      getTowns: 'towns/getTowns',
      getProviders: 'providers/getProviders',
      getFavorites: 'favorites/getFavorites',
      updateFavorite: 'favorites/updateFavorite',
    }),
    setIsList(isList) {
      this.isList = isList;
    },
    editFilters(key, value) {
      this.filters[key] = value;
    },
    toggleDisplayFilters() {
      this.displayFilters = !this.displayFilters;
    },
    async onChangePage(page) {
      const filters = {
        page: page,
        service_type_id: this.$route.query.service_type_id,
        town_id: this.$route.query.town_id,
      }
      await this.getProviders(filters);
      scrollToTop(window);
    },
    async onSearchSubmit(search) {
      this.searchTxt = search;
      await this.getProviders({search});
      scrollToTop(window);
    },
    async onTownFilterSelected(townId) {
      if(this.currentTownId === townId) {
        this.currentTownId = null;
        await this.getProviders();
      } else {
        this.currentTownId = townId;
        await this.getProviders({town_id: this.currentTownId});
      }
    },
    async onServiceTypeFilterSelected(serviceTypeId) {
      if(this.currentServiceTypeId === serviceTypeId) {
        this.currentServiceTypeId = null;
        await this.getProviders();
      } else {
        this.currentServiceTypeId = serviceTypeId;
        await this.getProviders({service_type_id: this.currentServiceTypeId});
      }
    },
    async onPriceFilterSelected(prices) {
      const hasAlreadySetPrices = {min_price: this.filters.min_price, max_price: this.filters.max_price} === prices;
      if (hasAlreadySetPrices) { this.editFilters('min_price', 0); await this.getProviders(); }
      else {
        this.editFilters('min_price', prices.min_price)
        this.editFilters('max_price', prices.max_price)
        await this.getProviders({min_price: this.filters.min_price, max_price: this.filters.max_price});
      }
    },
    async onReviewFilterSelected(mark) {
      const hasAlreadySetPrices = this.filters.mark === mark;
      if (hasAlreadySetPrices) { this.editFilters('mark', 0); await this.getProviders(); }
      else { this.editFilters('mark', mark); await this.getProviders({mark: this.filters.mark}); }

    },
    onClearFilters(type) {
      if(type === 'town') {
        this.currentTownId = null;
      } else if(type === 'serviceType') {
        this.currentServiceTypeId = null;
      } else {
        this.currentTownId = null;
        this.currentServiceTypeId = null;
      }
      this.getProviders();
    },
    async onFavorite(providerId) {
      await this.updateFavorite({
        favorite_ids: [providerId]
      })
      await this.getFavorites({customer_id: this.user.id, ids_only: 0})
    },
    onMobileFiltersPanelClick() {
      this.isDisplayMobileFilters = !this.isDisplayMobileFilters;
    },
  }
};
</script>