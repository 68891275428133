import { BASE_URL } from "@/utils";
import axios from 'axios';

export async function getProvidersAPI(params) {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/wedding-providers`, {params});
    return response.data;
  } catch (error) {
    console.log("===> getProvidersAPI error", error);
  }
}

export async function getProviderAPI(id) {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/wedding-providers/${id}`);
    return response.data;
  } catch (error) {
    console.log("===> getProviderAPI error", error);
  }
}
