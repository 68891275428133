<template>
  <div class="relative bg-white shadow-lg rounded-lg h-fit mx-4" :class="{'flex flex-row w-full': isList}">
    <div class="absolute right-2 top-2" v-if="provider && provider.id && !isLoading && $store.getters['auth/isAuthenticated']">
      <fav-pill-component @toggle-favorite-item="onFavorite" :provider-id="provider.id"></fav-pill-component>
    </div>
    <div :class="{'flex w-1/3': isList}" v-if="provider && mediaPreviewUrl">
      <img 
          :class="{'rounded-t-lg mx-auto h-[15rem]': !isList, 'w-full rounded-tl-lg rounded-bl-lg': isList}"
          class="h-full w-full object-cover"
          :src="mediaPreviewUrl" 
      />
    </div>
    <div class="py-2 px-2" :class="{'flex flex-col w-2/3': isList}">
      <div class="pt-1 pb-0.5">
        <div
            class="font-semibold text-ellipsis" v-if="provider"
        >
          {{ provider.name }}
        </div>
      </div>

      <div class="py-0.5 text-xs text-ellipsis" v-if="provider && provider.service_type">
          {{ provider.service_type.name }}
      </div>

      <div class="py-0.5 text-xs text-ellipsis" v-if="provider && provider.addresses && provider.addresses[0]">
        {{ provider.addresses[0].town_name + ', ' + provider.addresses[0].district_name }}
      </div>

      <div class="py-0.5 text-xs" v-if="provider.short_description && isList">
        {{ truncateStringWrapper(provider.short_description, 300) }}
      </div>
      <div class="py-0.5" v-if="provider && provider.mark">
        <review-stars-component :is-shown-mode="true" :stars="getStars(provider.mark)"></review-stars-component>
      </div>

      <div :class="{'flex justify-between': isList}">
        <div class="flex justify-between py-0.5" :class="{'w-1/2': isList}">
          <div class="flex">
            <font-awesome-layers class="fa-2x">
              <font-awesome-icon class="text-gray-200" icon="comment-dollar" />
            </font-awesome-layers>&nbsp;
            <div class="flex flex-col text-xs">
              <span class="font-semibold">Prix min.</span>
              <span>{{ provider.min_price }} F</span>
            </div>
          </div>

          <div class="flex">
            <font-awesome-layers class="fa-2x">
              <font-awesome-icon class="text-gray-200" icon="comment-dollar" />
            </font-awesome-layers>&nbsp;
            <div class="flex flex-col text-xs">
              <span class="font-semibold">Prix max.</span>
              <span>{{ provider.max_price }} F</span>
            </div>
          </div>
        </div>

        <div class="py-0.5" :class="{'w-1/3': isList}">
          <button @click="onClick(provider.id)" class="w-full bg-red-800 text-white py-2 rounded-lg hover:bg-red-900">
            contacter
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {truncateString, STORAGE_BASE_URL} from "@/utils";
import {COMPONENT_STAR_TYPES} from "@/types";

export default {
  name: 'CardComponent',
  props: {
    provider: {
      type: Object,
      required: true,
    },
    isList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      storageBaseUrl: STORAGE_BASE_URL,
      isLoading: false,
    }
  },
  watch: {
    '$store.state.favorites.isLoading': function (val) {
      this.isLoading = val
    },
  },
  computed: {
    mediaPreviewUrl() {
      return this.provider
          && this.provider.medias && this.provider.medias[0]
          ? this.goodPath(this.provider.medias[0])
          : this.storageBaseUrl + 'photos/default.png';
    }
  },
  mounted() {},
  methods: {
    truncateStringWrapper(value, num) {
      return truncateString(value, num);
    },
    onClick(providerId) {
      window.location = '/prestataires/' + providerId;
    },
    videoId(value) {
      const var1 = value.split('watch?v=')
      const var2 = var1[var1.length - 1].split("&")
      return `https://img.youtube.com/vi/${var2[0]}/hqdefault.jpg`
    },
    goodPath(media) {
      const { type, path } = media;
      if(type === 'youtube_video' && path) { return this.videoId(path) }
      if(type === 'photo' && path) { return this.storageBaseUrl + path }
      return null
    },
    onFavorite(providerId) {
      this.$emit('toggle-favorite-card-item', providerId)
    },
    getStars(currentMark) {
      const stars = [];
      let roundMark = 0;
      if (currentMark) {
        roundMark = Number(currentMark);
      }
      for (let i = 1; i <= 5; i++) {
        if (i <= roundMark) {
          stars.push({ type: COMPONENT_STAR_TYPES.FULL });
        } else {
          stars.push({ type: COMPONENT_STAR_TYPES.EMPTY });
        }
      }
      return stars;
    },
  }
}

</script>

<style scoped>

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>