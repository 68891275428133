<template>
<div class="flex flex-wrap">
  <div class="flex flex-row" v-for="(page, index) in pages" :key="index">
    <a :href="page.path" class="text-xs text-gray-500 hover:text-red-800">
      {{ page.label }}
    </a>
    <div v-if="index < pages.length - 1" class="px-1 py-0">
      >
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "BreadcrumbComponent",
  props: {
    pages: {
      type: Array,
      required: true,
    }
  }
}
</script>
