<template>
  <div class="flex flex-col w-full items-center">
    <div class="w-full fixed top-0 mx-0 bg-white z-10 flex shadow-md">
      <div class="flex container mx-auto">
        <nav-list-component current-page="wedding-providers"></nav-list-component>
      </div>
    </div>
    <div class="flex w-full pt-20 ">
      <banner-component :is-display-search-inputs="isDisplaySearchInputs"></banner-component>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavComponent",
  props: ["isDisplaySearchInputs"],
  methods: {
    onTownFilterSelected(townId) {
      this.$emit('town-filter-selected-on-list', townId);
    },
    onServiceTypeFilterSelected(serviceTypeId) {
      this.$emit('service-type-filter-selected-on-list', serviceTypeId);
    }
  }
}
</script>
