<template>
  <div class="w-full" ref="contact-form" id="contact-form">
    <div class="flex">
      <div class="flex items-center w-full -mt-28">
        <div class="px-2 md:py-6 bg-white rounded-md sticky top-0 w-full text-center">
          <div v-if="$store.state.quoteRequests.isSuccess" class="p-4 mb-4 text-xs text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
            <button class="flex justify-end" @click="onClose">
              <font-awesome-icon icon="fa-xmark"></font-awesome-icon>
            </button>
            <div class="flex">
              Merci pour votre message.<br />
              Le prestataire vous recontactera dès que possible.
            </div>
          </div>
          <form @submit.prevent="requestQuote(formTypes.MESSAGE)" class="flex flex-col w-full items-center px-2">
            <div class="text-lg py-0.5">Demander un devis</div>
            <div class="py-1 w-full">
              <input class="w-full px-2 py-2 rounded-md bg-gray-100" placeholder="Prénom" v-model="given_names" />
              <div class="flex" v-if="errors && errors['given_names'] && errors['given_names'].length > 0">
                <span class="text-red-800">{{ errors['given_names'][0] }}</span>
              </div>
            </div>
            <div class="py-1 w-full">
              <input class="w-full px-2 py-2 rounded-md bg-gray-100" placeholder="Email" v-model="email" />
              <div class="flex" v-if="errors && errors['email'] && errors['email'].length > 0">
                <span class="text-red-800">{{ $store.state.quoteRequests.errors['email'][0] }}</span>
              </div>
            </div>
            <div class="py-1 w-full">
              <input class="w-full px-2 py-2 rounded-md bg-gray-100" placeholder="Téléphone" v-model="phone_number" />
              <div
                  class="flex"
                  v-if="errors && errors['phone_number'] && errors['phone_number'].length > 0"
              >
                <span class="text-red-800">{{ errors['phone_number'][0] }}</span>
              </div>
            </div>
            <div class="py-1 w-full">
              <textarea rows="5" class="w-full px-2 py-2 bg-gray-100 rounded-md" v-model="message"></textarea>
              <div class="flex" v-if="errors && errors['message'] && errors['message'].length > 0">
                <span class="text-red-800">{{ errors['message'][0] }}</span>
              </div>
            </div>
            <div class="py-1 w-full">
              <button v-if="$store.getters['auth/isAuthenticated']" type="submit" class="bg-red-800 text-white rounded-full w-full py-2">
                Envoyer ma demande
              </button>
              <button v-else @click="redirectToLogin" class="bg-red-800 text-white rounded-full w-full py-2">
                Envoyer ma demande
              </button>
            </div>
          </form>
          <div class="flex flex-col">
            <div class="flex justify-center items-center">Ou, contactez-nous par :</div>
            <div class="flex justify-center items-center">
              <form v-if="provider && provider.phone_number_1" @submit.prevent="requestQuote(formTypes.PHONE)">
                <button
                    v-if="$store.getters['auth/isAuthenticated']"
                    class="bg-white border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2" type="submit"
                >
                  Téléphone
                </button>
                <button
                    v-else @click="redirectToLogin"
                    class="bg-white border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2"
                >
                  Téléphone
                </button>
              </form>&nbsp;&nbsp;
              <form v-if="provider && provider.website" @submit.prevent="requestQuote(formTypes.WEBSITE)">
                <button
                    v-if="$store.getters['auth/isAuthenticated']"
                    class="bg-white border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2"
                    type="submit"
                >
                  Site web
                </button>
                <button
                    v-else @click="redirectToLogin"
                    class="bg-white border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2"
                >
                  Site web
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {QUOTE_REQUEST_TYPE, LOGIN_URL} from "@/utils";

export default {
  name: "contact-form",
  props: ['user', 'provider'],
  data: () => {
    return {
      given_names: null,
      email: null,
      phone_number: null,
      message: 'Bonjour, j\'aimerais avoir plus d\'informations sur un ou plusieurs de vos produits s\'il vous plait. \nMerci !',
      formTypes: QUOTE_REQUEST_TYPE,
      errors: null
    }
  },
  mounted() {},
  watch: {
    user(next) {
      if (next && next.id) {
        this.given_names = next.given_names;
        this.email = next.email;
        this.phone_number = next.phone_number_1;
      }
    },
    '$store.state.auth.user'(next) {
      if (next && next.id) {
        this.given_names = next.given_names;
        this.email = next.email;
        this.phone_number = next.phone_number_1;
      }
    },
    '$store.state.quoteRequests.errors'(next) {
      if (next) {
        this.errors = next
      }
    }
  },
  methods: {
    dataFormatter(type) {
      return {
        given_names: this.given_names,
        email: this.email,
        phone_number: this.phone_number,
        message: this.message,
        type,
      }
    },
    requestQuote(type) {
      const data = this.dataFormatter(type);
      this.$emit('quote-request-form', data);
    },
    onClose() {
      this.$store.commit('quoteRequests/setSuccess', false);
    },
    redirectToLogin() {
      window.location = LOGIN_URL
    }
  }
}
</script>
