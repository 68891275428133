<template>
  <div class="main-container">
    <router-view></router-view>
    <footer>
      <footer-component></footer-component>
    </footer>
  </div>
</template>

<script>
import './index.css'

export default {
  name: 'App',
}
</script>

<style scoped>
.main-container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
</style>
