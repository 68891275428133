import { createRouter, createWebHistory } from 'vue-router';
import ProviderIndex from "@/pages/provider-index";
import ProviderDetail from "@/pages/provider-detail";
import CallbackPage from '@/pages/callback';

const routes = [
    {
        path: "/",
        redirect: "/prestataires",
    },
    { path: '/prestataires', component: ProviderIndex },
    {
        name: 'providerDetail',
        path: '/prestataires/:id',
        component: ProviderDetail
    },
    {
        path: '/callback',
        component: CallbackPage,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return {
                el: '#app',
            }
        }
    }
})

export default router;