<template>
  <div
      class="px-4 w-full"
      :class="{'flex flex-col md:flex-row md:flex-wrap': !isList, 'flex flex-col': isList}"
  >
    <div class="py-4 h-fit" :class="{'w-full md:w-1/2 lg:w-1/3': !isList, 'w-full': isList}" v-for="(provider, key) in data" :key="key">
      <div>
        <card-item-component
            :is-list="isList" :provider="provider"
            @toggle-favorite-card-item="toggleFavoriteCardItem"
        ></card-item-component>
      </div>
    </div>
  </div>
</template>

<script>
import CardItemComponent from "@/components/card/card-item";
export default {
  components: {
    CardItemComponent,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    isList: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleFavoriteCardItem(providerId) {
      this.$emit('on-favorite-toggle', providerId);
    },
  },
}
</script>
