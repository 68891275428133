import {BASE_URL, requestConfig} from "@/utils";
import axios from 'axios';

export async function getCommentsAPI(params) {
    const config = {
        ...params,
        per_page: 5
    }
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/wedding-provider-comments`, { params: config });
        return response.data;
    } catch (error) {
        if (error.response.data) { throw error.response.data }
        throw {message: 'Internal server error', errors: null};
    }
}

export async function storeCommentAPI(data, params) {
    const config = requestConfig(params);
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/wedding-provider-comments`, data, config);
        return response.data;
    } catch (error) {
        if (error.response.data) { throw error.response.data }
        throw {message: 'Internal server error', errors: null};
    }
}

export async function deleteCommentAPI(commentId, params) {
    const config = requestConfig(params);
    try {
        await axios.delete(`${BASE_URL}/api/v1/wedding-provider-comments/${commentId}`, config);
    } catch (error) {
        if (error.response.data) { throw error.response.data }
        throw {message: 'Internal server error', errors: error};
    }
}