import { getMediasAPI } from "@/apis/medias";

export const state = () => ({
  isLoading: false,
  errors: {},
  medias: []
})

export const actions = {
  async getMedias({ commit }, params = {}) {
    commit('setLoading', true);
    try {
        const response = await getMediasAPI(params);
        commit('setMedias', response.data);
        commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setMedias', []);
      commit('setErrors', error.response.data);
    }
  }
}

export const mutations = {
  setLoading(state, value) {
    state.isLoading = value
  },
  setErrors(state, value) {
    state.errors = value
  },
  setMedias(state, value) {
    state.medias = value
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}