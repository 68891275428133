<template>
  <div class="fixed top-0 left-0 w-screen h-screen z-20 overflow-hidden">
    <div class="bg-gray-200 h-full">
      <div class="flex flex-row bg-white py-2">
        <div class="flex flex-row flex-1">
          <div class="px-2 h-fit w-fit flex items-center text-gray-500 text-xs">
            <button class="flex items-center border rounded-full px-4 py-2" @click="share">
              <font-awesome-layers>
                <font-awesome-icon class="text-gray-500" icon="fa-share-nodes"></font-awesome-icon>
              </font-awesome-layers>&nbsp;
              <span>
                Partager
              </span>
            </button>&nbsp;
          </div>
        </div>
        <div class="flex flex-row flex-1 items-center justify-items-center">
          <div class="w-full text-center text-gray-700 text-xs">
            {{ provider.name }}
          </div>
        </div>
        <div class="flex flex-row flex-1 items-center justify-end w-full">
          <div class="flex px-2 border-r text-gray-500">
            <font-awesome-layers>
              <font-awesome-icon icon="fa-th-large"></font-awesome-icon>
            </font-awesome-layers>
          </div>
          <div class="flex px-2 border-r text-gray-500" v-if="medias">
            {{ `${index + 1}/${medias.length}` }}
          </div>
          <button class="py-2 px-2 md:mr-8 md:ml-4 border-gray-500 text-gray-500" @click.prevent="$emit('display-portfolio-modal', false)">
            <font-awesome-layers>
              <font-awesome-icon icon="fa-xmark"></font-awesome-icon>
            </font-awesome-layers>
          </button>
        </div>
      </div>

      <div class="flex flex-col md:flex-row mx-auto my-2 md:my-8 mx-8">
        <div class="w-full lg:flex ld:flex-row ld:w-2/3">
          <div class="flex md:flex-1 bg-white w-full max-h-full items-center mx-2 lg:mr-2 lg:ml-0">
            <button class="px-4 py-2 border h-fit w-fit rounded-full" @click="prev">
              <font-awesome-icon class="text-gray-600" icon="fa-angle-left"></font-awesome-icon>
            </button>
            <div class="w-full h-full md:h-[45rem]" v-if="currentMedia && currentMedia.type && currentMedia.type === 'photo'">
              <img
                  class="mx-auto w-auto h-full object-cover"
                  :src="url({ path: currentMedia.path, type: currentMedia.type })"
              />
            </div>
            <div class="w-full h-full md:h-[45rem]" v-if="currentMedia && currentMedia.type && currentMedia.type === 'youtube_video'">
              <iframe :src="url({path: currentMedia.path, type: 'youtube_video'})" class="mx-auto w-full h-full" width="auto" height="720" allowfullscreen></iframe>
            </div>
            <div class="w-full" v-if="!currentMedia || !currentMedia.type">
              Chargement en cours ...
            </div>
            <button class="px-4 py-2 border h-fit w-fit rounded-full" @click="next">
              <font-awesome-icon class="text-gray-600" icon="fa-angle-right"></font-awesome-icon>
            </button>
          </div>
        </div>
        <div class="hidden bg-white items-center justify-center lg:flex lg:flex-row lg:w-1/3 lg:ml-2 px-8 py-2" v-if="provider">
          <contact-form-component :provider="provider" @quote-request-form="sendQuoteRequest"></contact-form-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodPath } from '@/utils'

export default {
  name: "portfolio-modal-component",
  props: ['medias', 'provider'],
  emits: ['display-portfolio-modal'],
  data: () => ({
    currentMedia: null,
    index: 0,
  }),
  watch: {
    medias: {
      handler: function (val) {
        this.currentMedia = val[0]
      },
      immediate: true
    }
  },
  methods: {
    url: (media) => getGoodPath(media),
    next() {
      this.index = this.medias.length <= this.index + 1 ? this.index : this.index + 1;
      this.currentMedia = this.medias[this.index];
    },
    prev() {
      this.index = this.index > 1 ? this.index - 1 : 0;
      this.currentMedia = this.medias[this.index];
    },
    sendQuoteRequest(data) {
      this.$emit('quote-request-form-modal', data);
    },
    share() {
      console.log("===> share")
    }
  }
}
</script>

<style scoped>

</style>