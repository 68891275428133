import { getServiceTypesAPI } from "@/apis/serviceTypes";

export const state = () => ({
    isLoading: false,
    errors: {},
    serviceTypes: []
})

export const actions = {
    async getServiceTypes({ commit }, params) {
        commit('setLoading', true);
        try {
            const response = await getServiceTypesAPI(params || null);
            commit('setLoading', false);
            commit('setServiceTypes', response.data);
        } catch (e) {
            console.log("===> getServiceTypesAPI error", e)
            commit('setLoading', false)
            commit('setServiceTypes', [])
            commit('setErrors', e.response.data)
            throw new Error(e)
        }
    }
}

export const mutations = {
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setServiceTypes(state, value) {
        state.serviceTypes = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}