import { createStore } from "vuex"
import providers from './providers'
import addresses from './addresses'
import medias from './medias'
import serviceTypes from './serviceTypes'
import towns from './towns'
import comments from './comments'
import auth from './auth'
import quoteRequests from './quoteRequests'
import favorites from './favorites'


const store = createStore({
    modules: {
        providers,
        addresses,
        medias,
        serviceTypes,
        towns,
        comments,
        auth,
        quoteRequests,
        favorites,
    }
})

export default store;