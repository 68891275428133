<template>
  <div class="w-full">
    <div v-show="medias && displayModal">
      <portfolio-modal-component
          @display-portfolio-modal="setDisplayModal"
          @quote-request-form-modal="sendRequestQuote"
          :provider="provider"
          :medias="medias"></portfolio-modal-component>
    </div>

    <div class="w-full" @click.stop="setDisplayModal(true)">
      <div class="flex w-full" v-if="medias">
        <portfolio-preview-component :medias="medias"></portfolio-preview-component>
      </div>
    </div>
  </div>
</template>

<script>
import PortfolioPreviewComponent from "@/components/portfolio/porfolio-preview";
import PortfolioModalComponent from "@/components/portfolio/portfolio-modal";

export default {
  name: "PortfolioComponent",
  components: {PortfolioPreviewComponent, PortfolioModalComponent},
  props: ['medias', 'provider', 'displayModalProps'],
  computed: {
  },
  data() {
    return {
      displayModal: false,
    }
  },
  watch: {
    displayModalProps(next) {
      if(next) {
        this.setDisplayModal(next)
        this.$emit('portfolio-modal-opened')
      }
    }
  },
  mounted() {},
  methods: {
    setDisplayModal(value) {
      this.displayModal = value
    },
    sendRequestQuote(data) {
      this.$emit('quote-request', data)
    }
  }
}
</script>
