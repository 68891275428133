<template>
  <button
      :class="`rounded-full bg-gray-100 px-2 py-2 shadow flex justify-center items-center`"
      @click="onFavorite(providerId)"
  >
    <font-awesome-icon v-if="isFav" class="text-gray-600" icon="fa-solid fa-heart"></font-awesome-icon>
    <font-awesome-icon v-else class="text-gray-600" icon="fa-regular fa-heart"></font-awesome-icon>
  </button>
</template>

<script>
export default {
  name: "FavPillComponent",
  props: {
    providerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isFav: false,
    };
  },
  watch: {
    providerId(next) {
      this.initFav(next)
      return next
    },
  },
  mounted() {
    this.initFav(this.providerId)
  },
  methods: {
    initFav(providerId) {
      if (providerId) {
        this.isFav = this.isFavorite(this.providerId);
      }
    },
    isFavorite(providerId) {
      const favorites = this.$store.state.favorites.favorites || [];
      return [...favorites].includes(providerId);
    },
    onFavorite(providerId) {
      if (!providerId) {
        console.log('onFavorite error')
      }
      this.isFav = true;
      this.$emit('toggle-favorite-item', providerId)
    }
  }
}
</script>

<style scoped>

</style>