import { getAddressesAPI } from "@/apis";

export const state = () => ({
  isLoading: false,
  errors: {},
  addresses: []
})

export const actions = {
  async getAddresses({ commit }, params) {
    commit('setLoading', true);
    try {
      const response = await getAddressesAPI(params);
      commit('setAddresses', response.data);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setAddresses', []);
      commit('setErrors', error.response.data);
    }
  }
}

export const mutations = {
  setLoading(state, value) {
    state.isLoading = value
  },
  setErrors(state, value) {
    state.errors = value
  },
  setAddresses(state, value) {
    state.addresses = value
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}