<template>
<div class="flex">
  <font-awesome-layers class="fa-2x">
    <font-awesome-icon class="text-gray-400" icon="comment-dollar" />
  </font-awesome-layers>&nbsp;
  <div class="flex flex-col text-xs">
    <span class="font-semibold">{{ label }}</span>
    <span>{{ value }} F</span>
  </div>
</div>
</template>

<script>
export default {
  name: "price-item",
  props: ['label', 'value']
}
</script>

<style scoped>

</style>