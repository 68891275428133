import { createApp } from 'vue/dist/vue.esm-bundler';

import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faStar,
    faBars,
    faEnvelope,
    faMobileAlt,
    faHeart as faHeartSolid,
    faList,
    faTh,
    faSearch,
    faXmark,
    faCommentDollar,
    faAngleLeft,
    faAngleRight,
    faShareNodes,
    faThLarge,
    faAngleUp,
    faAngleDown,
    faLocation,
    faPlusCircle,
    faUser,
    faPaperPlane,
    faPhone,
    faEarthAfrica,
} from '@fortawesome/free-solid-svg-icons'
import {
    faHeart as faHeartRegular,
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router';
import store from './store';

import Breadcrumb from '@/components/breadcrumb'
import Banner from '@/components/banner'
import Nav from '@/components/nav/nav'
import NavItem from '@/components/nav/nav-list'
import Footer from '@/components/footer'
import Spinner from '@/components/common/spinner'

import AddressList from '@/components/address/address-list'
import AddressItem from '@/components/address/address-item'

import CommentItem from '@/components/comment/comment-item'
import CommentList from '@/components/comment/comment-list'
import CommentForm from '@/components/comment/comment-form'

import CardList from '@/components/card/card-list'
import CardItem from '@/components/card/card-item'

import FilterList from '@/components/filter/filter-list'
import FilterItemTown from '@/components/filter/filter-item-town'
import FilterItemReview from '@/components/filter/filter-item-review'
import FilterItemPrice from '@/components/filter/filter-item-price'
import FilterItemServiceType from "@/components/filter/filter-item-service-type";

import PillComponent from "@/components/pill/pill.vue";
import FavPillComponent from "@/components/common/fav-pill.vue";

import ReviewStars from '@/components/review/review-stars'
import ReviewList from '@/components/review/review-list'
import ReviewMarkList from '@/components/review/review-mark-list'

import ContactForm from '@/components/contact-form'
import Portfolio from '@/components/portfolio/portfolio'
import Pagination from '@/components/pagination'

const app = createApp(App);
app.config.globalProperties.$http = axios;
app.use(router);
app.use(store);

library.add(
    faStar,
    faBars,
    faEnvelope,
    faMobileAlt,
    faHeartRegular,
    faHeartSolid,
    faList,
    faTh,
    faSearch,
    faXmark,
    faCommentDollar,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAngleDown,
    faShareNodes,
    faThLarge,
    faLocation,
    faPlusCircle,
    faTh,
    faUser,
    faPaperPlane,
    faPhone,
    faEarthAfrica,
);

// register component
app.component('font-awesome-icon', FontAwesomeIcon)
    .component('breadcrumb-component', Breadcrumb)
    .component('banner-component', Banner)
    .component('contact-form-component', ContactForm)
    .component('footer-component', Footer)
    .component('nav-component', Nav)
    .component('nav-list-component', NavItem)
    .component('portfolio-component', Portfolio)
    .component('address-list-component', AddressList)
    .component('address-item-component', AddressItem)
    .component('card-list-component', CardList)
    .component('card-item-component', CardItem)
    .component('comment-list-component', CommentList)
    .component('comment-item-component', CommentItem)
    .component('comment-form-component', CommentForm)
    .component('filter-list-component', FilterList)
    .component('filter-item-town-component', FilterItemTown)
    .component('filter-item-review-component', FilterItemReview)
    .component('filter-item-price-component', FilterItemPrice)
    .component('filter-item-service-type-component', FilterItemServiceType)
    .component('review-stars-component', ReviewStars)
    .component('review-list-component', ReviewList)
    .component('review-mark-list-component', ReviewMarkList)
    .component('pagination-component', Pagination)
    .component('font-awesome-layers', FontAwesomeLayers)
    .component('spinner-component', Spinner)
    .component('pill-component', PillComponent)
    .component('fav-pill-component', FavPillComponent);

app.mount('#app');
