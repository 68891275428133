<template>
  <div class="main-container w-full">
    <div class="text-xl">
      Avis sur {{ provider.name }}
    </div>
    <hr />
    <review-mark-list-component :global-mark="provider.mark"></review-mark-list-component>
    <hr />
    <comment-list-component @comment-item-deleted="onCommentDelete" @comment-list-paginate="onPageChange" :comments="comments"></comment-list-component>
  </div>
</template>

<script>
import CommentListComponent from "@/components/comment/comment-list";

export default {
  name: "ReviewComponent",
  props: ['provider', 'comments'],
  components: {
    CommentListComponent,
  },
  methods: {
    onPageChange(currentPage) {
      this.$emit('review-comment-list-paginate', currentPage);
    },
    onCommentDelete(id) {
      this.$emit('review-comment-item-deleted', id);
    }
  }
}
</script>

<style scoped>
.main-container {
  padding: 1rem 0.5rem;
}

.header {
  font-size: 24px;
}
</style>
