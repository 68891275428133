import {getProvidersAPI, getProviderAPI} from "@/apis";

export const state = () => ({
    providers: {},
    provider: {},
    isLoading: false,
    errors: {},
})

export const actions = {
    async getProviders({ commit }, params = {}) {
        commit('setLoading', true);
        try {
            const response = await getProvidersAPI(params);
            commit('setLoading', false);
            commit('setProviders', response);
        } catch (e) {
            commit('setLoading', false);
            commit('setProviders', null);
            commit('setErrors', e.response);
            throw new Error(e)
        }
    },
    async getProvider({ commit }, { id }) {
        commit('setLoading', true);
        try {
            const response = await getProviderAPI(id);
            commit('setLoading', false);
            commit('setProvider', response.data);

        } catch (e) {
            commit('setLoading', false);
            commit('setProvider', null);
            commit('setErrors', e.response);
            throw new Error(e)
        }
    }
}

export const mutations = {
    setProviders(state, value) {
        state.providers = value
    },
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setProvider(state, value) {
        state.provider = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
